import { CSSObject } from '@mui/material'

import { IMenuFull } from './shared'

export interface IEvent {
  name: string
  startTime: string
}

interface CheckinUser {
  firstName: string
  lastName: string
  avatar: string
}

export interface ICheckin {
  user: CheckinUser
  beer: string
  createdAt: string
}

export type InfoPanel = 'announcement' | 'events' | 'checkins'

export interface IAdSlide {
  id: number
  displaySeconds: number
  imageFileName: string | null
  imageUrl: string | null
}

export interface IBoardText {
  minFont: number
  maxFont: number
}

export interface IBoardSettings {
  repeatSectionTitles: boolean
  text: {
    basic: IBoardText
    detail: IBoardText
    containers: IBoardText
  }
  disabledOptions: string[]
  defaultOptions: Partial<IDisplayInformation>
  layoutItemDetailOrder: Record<string, string[]>
  backgroundImageOpacity: number
  columns: number
  fontFamily: string
  fontSizing: FontSizing
  headerImageAlignment: string
  headerImageSize: number
  itemDetailOrder: string[]
  labelImageCornerRadius: string
  layout: 'default' | 'compressed' | 'table'
  newTagBackgroundColor: string
  onDeckTagBackgroundColor: string
  orientation: 'landscape' | 'portrait'
  primaryAccentColor: string
  theme: string
}

export interface CustomFontFamily {
  name: string
  url: string
}

export interface IDisplayInformation {
  showAbv: boolean
  showAdSlides: boolean
  showAnnouncements: boolean
  showBeerStyle: boolean
  showBreweryLocation: boolean
  showBreweryName: boolean
  showCheckins: boolean
  showCurrencySymbol: boolean
  showEvents: boolean
  showIbu: boolean
  showLabelImage: boolean
  showBeerLabelImage: boolean
  showWineLabelImage: boolean
  showSpiritLabelImage: boolean
  showRtdLabelImage: boolean
  showFoodLabelImage: boolean
  showGenericItemLabelImage: boolean
  showMenuTitles: boolean
  showPrice: boolean
  showSectionTitles: boolean
  showTapNumber: boolean
  showUntappdRating: boolean
  showNewStatus: boolean
  showCalories: boolean
  showOnDeckItems: boolean
  pageBreakAfterMenu: boolean
  showContainers: boolean
  showContainerName: boolean
  showContainerCalories: boolean
  showSpiritProducer: boolean
  showSpiritCategory: boolean
  showSpiritAbv: boolean
  showSpiritVintage: boolean
  showSpiritCalories: boolean
  showWineProducer: boolean
  showWineCategory: boolean
  showWineAbv: boolean
  showWineCharacteristics: boolean
  showWineVintage: boolean
  showWineCalories: boolean
  showWineRegion: boolean
  showBeerDescription: boolean
  showSpiritDescription: boolean
  showWineDescription: boolean
  showRtdDescription: boolean
  showFoodDescription: boolean
  showGenericItemDescription: boolean
  showRtdCalories: boolean
  showRtdCategory: boolean
  showRtdProducer: boolean
  showRtdVintage: boolean
  showRtdAbv: boolean
}

export interface ICustomizable extends IDisplayInformation {
  id: number
  name: string
  template: string
  theme: string
  locationId: number
  createdAt: string
  updatedAt: string
  fontFamilies: Record<string, string[]>
  customFontFamilies: CustomFontFamily[]
  announcement: string
  cssOverride: string
  columns: number
  labelImageCornerRadius: string
}

export interface IBoardFontSettings {
  primary: CSSObject
  secondary: CSSObject
  menuTitle: CSSObject
  sectionTitle: CSSObject
  onDeckTag: CSSObject
  newTag: CSSObject
  tapNumber: CSSObject // deprecated
  itemDetails: CSSObject
  itemTapNumber: CSSObject
  itemProducer: CSSObject
  itemName: CSSObject
  itemStyle: CSSObject
  itemAbv: CSSObject
  itemIbu: CSSObject
  itemCalories: CSSObject
  itemCharacteristics: CSSObject
  itemVintage: CSSObject
  itemLocation: CSSObject
  itemRating: CSSObject
  itemDescription: CSSObject
  container: CSSObject
  containerPrice: CSSObject
  announcement: CSSObject
  eventPrimary: CSSObject
  eventSecondary: CSSObject
  checkinName: CSSObject
  checkinDetails: CSSObject
  footer: CSSObject
}

export enum FontSizing {
  SHRINK_TO_FIT = 'shrink_to_fit',
  CONSISTENT = 'consistent',
  MANUAL = 'manual',
}

export interface IBoardOptions {
  public: boolean
  page: number
}

export interface IBoard extends ICustomizable {
  height: number
  width: number
  settings: IBoardSettings
  backgroundColor: string
  backgroundImage?: File | null
  backgroundImageFileName: string | null
  backgroundImageUrl: string | null
  backgroundImageOpacity: number
  headerImage?: File | null
  headerImageFileName: string | null
  headerImageUrl: string | null
  headerImageSize: number
  headerImageAlignment: string
  beersOnScreen: number
  fontFamily: string
  multipleDisplays: boolean
  orientation: 'landscape' | 'portrait'
  primaryAccentColor: string
  screenRotationSeconds: number
  menuTitleBackgroundColor: string
  menuTitleFontColor: string
  sectionTitleBackgroundColor: string
  sectionTitleFontColor: string
  untappdRatingColor: string
  adSlideDisplayStyle: string
  pageBreakAfterMenu: boolean
  website: string
  fontSettings: IBoardFontSettings
  fontSizing: FontSizing
  onDeckTagBackgroundColor: string
  newTagBackgroundColor: string
  itemDetailOrder: string[]
  menus: IMenuFull[]
  events: IEvent[]
  adSlides: IAdSlide[]
  checkins: ICheckin[]
  layout: 'default' | 'compressed' | 'table'
  containerLayout: 'below' | 'side_by_side' | 'stacked'
  defaults: Partial<IBoard>
}

export interface IBoardThemeOptions {
  [key: string]: Partial<IBoard>
}

export type BoardField = keyof IBoard
